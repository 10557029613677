import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SensorListComponent } from './sensor-list/sensor-list.component';
import { SensorDetailsComponent } from './sensor-details/sensor-details.component';
import { UserAccountComponent } from './user-account/user-account.component';


const routes: Routes = [
  { path: '', component: SensorListComponent},
  { path: 'user', component: UserAccountComponent},
  { path: 'details/:id', component: SensorDetailsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
