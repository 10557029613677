
<div class="sensor-list-header"></div>
<div class="sensor-list">

    <div class="sensor-field" *ngFor="let sensorField of sensorFields" routerLink="details/{{sensorField.id}}">
        <div class="sensor-field-data">
            <div class="header-div"><h2>{{ sensorField?.name }}</h2></div>
            <div class="fullwidth-div"> {{sensorField?.lastData?.time | date:'dd.MM.yy HH:mm'}}</div>
            <div class="fullwidth-div">
                <div class="stack-div"> {{sensorField?.lastData?.value}} {{sensorField?.unit}} </div>
                <div class="stack-div"> <span *ngIf="(sensorField.change >= 0)">+</span>{{sensorField?.change}} {{sensorField?.unit}} siste time</div>
            </div>
            <div class="fullwidth-div" *ngIf="sensorField.m3s">
                <div class="stack-div"> {{sensorField?.lastData?.value2 | number:'1.2-2'}} kbm/s </div>
                <div class="stack-div"> <span *ngIf="(sensorField.changeM3s >= 0)">+</span>{{sensorField?.changeM3s | number:'1.2-2'}} kbm/s siste time</div>
            </div>

        </div>
        <div class="sensor-field-graph">
            <app-small-chart [multi]="sensorField?.sensorData" [yScaleMin]="sensorField?.minimumLevel"></app-small-chart>
        </div>
    </div>
 </div>
