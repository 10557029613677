import { Component } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { UserService } from './services/user.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'POWAFA Vannføring';

  public currentUser: any;
  public loaded = false;

  public constructor(private cookieService: CookieService, public userService: UserService, private titleService: Title ) {

    let userid = cookieService.get("powafauserid");
    console.log(userid);
    if (userid !== "") {
      let userCredentials =  {userId: userid, email: "", name: ""};
      this.getUser(userCredentials);
    } 
    else {
      userService.getUserCredentials().subscribe((data: any[]) => {
        let userCredentials =  {userId: "", email: "", name: ""};
        userCredentials.userId = data[0].user_id;
        data[0].user_claims.map(claim => {
          if (claim.typ === "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress") {
            userCredentials.email = claim.val;
          } else if (claim.typ === "name") {
            userCredentials.name = claim.val;
          }
        })
        let cookieExpirationDate = new Date();
        cookieExpirationDate.setDate(cookieExpirationDate.getDate() +14);
        cookieService.set("powafauserid", userCredentials.userId, cookieExpirationDate);
        console.log(userCredentials);
        this.currentUser = userCredentials;
        this.getUser(userCredentials);
        
      }, error => {
        userService.authenticated = false;
      })
    }
  }

  private getUser(userCredentials: any) {
    this.userService.getUser(userCredentials).subscribe(user => {
      this.userService.currentUser = user;
      console.log(user);
      this.userService.expired = (new Date(this.userService.currentUser.expiration) < new Date());
      let expirationDate = new Date(this.userService.currentUser.expiration);
      expirationDate.setDate(expirationDate.getDate() -7);
      this.userService.expireWarning = (expirationDate < new Date());


      this.loaded = !this.userService.expired;
      this.userService.authenticated = true;
    });
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
