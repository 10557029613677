import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SensorServiceService {
  private SERVER_URL = "/api";
  constructor(private httpClient: HttpClient) { }

  public getAllSensors(){
    return this.httpClient.get(`${this.SERVER_URL}/SensorField`);
  }

  public getSensors(groupName: string) {
    return this.httpClient.get(`${this.SERVER_URL}/SensorField/GetByGroup/${groupName}`);
  }

  public getSensorData(sensorId: number) {
    return this.httpClient.get(`${this.SERVER_URL}/SensorField/${sensorId}`);
  }
}
