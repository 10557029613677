import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private SERVER_URL = "/api";
  public currentUser: any = null;
  public expired: boolean = false;
  public expireWarning: boolean = false;
  public authenticated: boolean = true; 
  constructor(private httpClient: HttpClient) { }

  public getUserCredentials() {
    return this.httpClient.get(`/.auth/me` );
  }

  public getUser(user: any) {
    return this.httpClient.post(`${this.SERVER_URL}/User`, user);
  }
}
