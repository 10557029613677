import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.css']
})
export class UserAccountComponent implements OnInit {

  public user: any;
  public currentDate = new Date();
  public expired: boolean;

  constructor(private userService: UserService) { 
    this.user = userService.currentUser;
    this.expired = userService.expired;
  }



  ngOnInit(): void {
  }

}
