import { Component, OnInit } from '@angular/core';
import { SensorServiceService } from '../services/sensor-service.service'
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.css']
})
export class SensorListComponent implements OnInit {

  sensorFields = [];

  constructor(private userService: UserService, private sensorService: SensorServiceService, private route: ActivatedRoute, private app: AppComponent) {
    let test = 0;
   }

  ngOnInit(): void {
    let groupName = this.route.snapshot.queryParamMap.get('collection');
    groupName = groupName !== null ? groupName : this.userService.currentUser.subscriptionGroup;
    this.app.setTitle("Sensor liste - POWAVA Vannføring")

    if (groupName === 'all') {
      this.sensorService.getAllSensors().subscribe((data: any[]) => {
        console.log(data);
        this.sensorFields = data;
      })
    } 
    else {
      this.sensorService.getSensors(groupName).subscribe((data: any[]) => {
        console.log(data);
        this.sensorFields = data;
      })
    }
  }

}
