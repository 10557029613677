import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { SensorServiceService } from '../services/sensor-service.service';
import { AppComponent } from '../app.component';



@Component({
  selector: 'app-sensor-details',
  templateUrl: './sensor-details.component.html',
  styleUrls: ['./sensor-details.component.css']
})
export class SensorDetailsComponent implements OnInit {

  sensorField: any;
  autoScale: boolean = false;
  sensorId = 0;

  constructor(private route: ActivatedRoute, private sensorService: SensorServiceService, private app: AppComponent) {

  }


  ngOnInit() {
    this.app.setTitle( "Sensor Details - POWAVA Vannføring")

    this.sensorId = (Number)(this.route.snapshot.paramMap.get('id'));
    this.sensorService.getSensorData(this.sensorId).subscribe((data: any) => {
      console.log(data);
      this.sensorField = data;
      this.app.setTitle(data?.name + " - POWAVA Vannføring");
    })


  }



}
