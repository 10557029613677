<ngx-charts-line-chart
  [view]="view"
  [scheme]="colorScheme"
  [legend]="legend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxis]="xAxis"
  [yAxis]="yAxis"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [yScaleMin]="yScaleMinimum"
  [autoScale]="autoScale"
  [timeline]="true"
  [results]="dataValues"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  [xAxisTickFormatting]="formatDate"
  >
</ngx-charts-line-chart>
