<br/>
<br/>
<div style="background-color: #dddddd; padding:10px;">
<h1 *ngIf="!expired" style="color: black;">Ditt Powafa abonnement</h1>
<h1 *ngIf="expired" style="color: red;">Ditt Powafa abonnement har utløpt</h1>

<p>Navn: {{user.name}}</p>
<p>Email: {{user.email}}</p>
<p>Google User Id: {{user.userId}}</p>

<p>Abonnementstype: {{user.subscriptionGroup}}</p>
<p *ngIf="!expired">Abonnementet ditt er gyldig til {{user.expiration  | date:'dd.MM.yyyy hh:mm'}}</p>

<p *ngIf="user.subscriptionGroup === 'prøveabonnement'">
    Du har nå et prøveabonnement på Powafa vannføringsensorer som er gyldig i 3 dager fram til {{user.expiration | date:'dd.MM.yy'}}
</p>

<p>Du kan bestille nytt abonnement eller fornye eksisterende ved å betale med Vipps til Powafa AS (Vipps nummer: 611717). Ved betaling må Google konto oppgis på sms til 917 06 200 siden pålogging skjer med autentisering av den Google-kontoen du velger å oppgi til Powafa. Det kan ta opptil 24 timer å registrere nytt abonnement etter at betaling er gjennomført, det er derfor lurt å fornye abonnementet senest 24 timer før utløpsdato hvis man ikke vil miste tilgang.
</p>
<p>For mer informasjon se <a href="https://www.powafa.no/powafa-abonnement/" target="_blank">https://www.powafa.no/powafa-abonnement</a></p>

<p><a routerLink="/">Tilbake til forsiden</a></p>

</div>