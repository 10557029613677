import { Component, NgModule, Input, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule,  } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-full-chart',
  templateUrl: './full-chart.component.html',
  styleUrls: ['./full-chart.component.css']
})
export class FullChartComponent implements OnInit {
  @Input() dataValues: any[];
  @Input() yScaleMin: any = null;
  @Input() autoScale: boolean = false;
  view: any[] = [800, 400];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = false;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Vannstand';
  yAxisLabel: string = 'Tidspunkt';
  timeline: boolean = true;
  yScaleMinimum: any;

  xAxisTicks = [new Date("2020-05-30"),new Date("2020-05-31"),new Date("2020-06-01"),new Date("2020-06-02"),new Date("2020-06-03"),new Date("2020-06-04")]


  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  
  public innerWidth: any;

  constructor() {

  }
  
  ngOnInit() {
      this.innerWidth = window.innerWidth;
      /*if (this.innerWidth < 500)
        this.view = [400,400];
      else */
        this.view = [this.innerWidth - 50, 400];

      if (this.autoScale) {
        this.yScaleMinimum = null;
      }
      else {
        this.yScaleMinimum = this.yScaleMin;
      }
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  formatDate(val) {
    let date = new Date(val);
    return date.toLocaleDateString( "no-NB", { year: "2-digit", month: "2-digit", day: "2-digit" }) + " " + date.toLocaleTimeString("no-NB", { hour: "2-digit", minute: "2-digit"});
    
    // return 123;
  }
}