
<div class="detail-content">
    <div class="detail-header">
        <h1>{{sensorField.name}}</h1>
        <p> Sist Oppdatert: {{sensorField.lastData?.time  | date:'dd.MM.yy HH:mm'}}</p>
        <p>
            Siste verdi: {{sensorField.lastData?.value }} {{sensorField.unit}}
            <span *ngIf="sensorField.m3s" style="font-weight: bold;"> {{sensorField.lastData?.value2 | number:'1.2-2'}} kbm/s</span>
        </p>
        <p>
            Endring siste time: <span *ngIf="(sensorField.change >= 0)">+</span>{{sensorField.change }} {{sensorField.unit}}
            <span *ngIf="sensorField.m3s"> (<span *ngIf="(sensorField.changeM3s >= 0)">+</span>{{sensorField.changeM3s | number:'1.2-2'}} kbm/s)</span>
        </p>
    </div>
    <div>
        <mat-tab-group>
            <mat-tab label="Diagram">
                <div class="detail-chart">
                    <app-full-chart [dataValues]="sensorField.sensorData" [autoScale]="autoScale" [yScaleMin]="sensorField.minimumLevel"></app-full-chart>
                </div>

            </mat-tab>
            <mat-tab label="Tabell">
                <div class="detail-table">
                    <table>
                        <tr><th>Tidspunkt</th><th>Nivå</th><th>kbm/s</th></tr>
                        <tr *ngFor="let value of sensorField.sensorData[0].series | orderBy: 'time' : true">
                            <td>{{value.time | date:'dd.MM.yy HH:mm'}}</td>
                            <td>{{value.value1 | number:'1.2-2'}} {{sensorField.unit}}</td>
                            <td>{{value.value2 | number:'1.2-2'}}</td>
                        </tr>
                    </table>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

</div>
