
<div class="app-container">
<div>
  <span class="powafa-logo" routerLink="/">Powafa</span>
</div>
<div *ngIf="userService.expireWarning" class="warningbox">Abonnementet ditt utløper snart. <a routerLink="/user">Se opplysninger om ditt abonnement.</a></div> 
<div *ngIf="!userService.authenticated" class="warningbox"> 
  <p>Du må logge inn for å bruke denne appen</p>
  <p><a href="/.auth/login/google/callback">Logg inn med Google konto</a></p>
</div>

<router-outlet *ngIf="loaded"></router-outlet>

<app-user-account *ngIf="userService.expired"></app-user-account>

<br>
<p>Levert av <a href="https://www.powafa.com">Powafa.com</a></p>

<a routerLink="/user">Opplysninger om ditt abonnement</a>
</div>